import React, { useEffect, useState } from 'react';

const CLIENT_ID = '255135197255586';
const REDIRECT_URI = 'https://bonnsalsahub.com/auth/facebook/callback';

const InstagramLoginButton = () => {
    const handleLogin = () => {
        const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`;
        window.location.href = authUrl;
    };

    // state to check login status
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // check if the user is logged in
    useEffect(() => {
        const url = window.location.href;
        if (url.includes('?code=')) {
            const code = url.split('?code=')[1];
            console.log(code);
            setIsLoggedIn(true);
        }
    }, []);
    

    return (
        <>
            <button onClick={handleLogin}>Login with Instagram</button>
            {isLoggedIn && <p>Logged in!</p>}
        </>
    );
};

const FacebookAuth = () => {
    return (
        <div>
            <InstagramLoginButton />
        </div>
    );
}

export default FacebookAuth;