import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../firebase';
import { CardMedia, Typography, Skeleton } from '@mui/material';

const ImageLoader = ({ imagePath, altText, height }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const defaultHeight = 280;

    useEffect(() => {
        const fetchImage = async () => {
            if (!imagePath) {
                return; // Exit if imagePath is null, undefined, or blank
            }
            try {
                const imageRef = ref(storage, imagePath);
                const url = await getDownloadURL(imageRef);
                setImageUrl(url);
            } catch (e) {
                console.error("Failed to fetch image: ", e);
                setError(e);
            }
        };

        fetchImage();
    }, [imagePath]);

    const handleImageLoaded = () => {
        setIsLoading(false);
    };

    return (
        imageUrl ? (
        <CardMedia
            component="img"
            height={height || defaultHeight}
            image={imageUrl}
            alt={altText}
            onLoad={handleImageLoaded}
            style={{
                opacity: isLoading ? 0 : 1,
                transition: 'opacity 0.5s ease-in-out'
            }}
        />
        ) : (
        <div style={{ position: 'relative', height: height || defaultHeight }}>
            <Skeleton variant="rectangular" width="100%" height={height || defaultHeight} />
            <Typography variant="body2" color="textSecondary"   
            style={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)' 
            }}>
            {error ? "Image could not be loaded" : "Loading..."}
            </Typography>
        </div>
        )
    );
};

export default ImageLoader;