import {Typography} from "@mui/material";

const iconStyle = { 
    marginRight: '8px', 
    opacity: 0.54 
};

const textStyle = {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    color: 'textSecondary',
    variant: 'body2',
};

const IconText = ({ icon: Icon, children }) => (
    <Typography variant="body2" style={textStyle}>
        <Icon fontSize="small" style={iconStyle} />
        {children}
    </Typography>
);

export default IconText;