import { Typography, Card, CardContent, Button, CardActions, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import ImageLoader from './common/ImageLoader';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DanceIcon from '@mui/icons-material/EmojiPeople';
import IconText from './ui/common/IconText.js';
import PriceBar from './ui/event_card/PriceBar';


const EventCard = ({ event }) => {
  
    return (
        <Link
            to={`/event/${event.id}`} 
            aria-label={`Details for ${event.title}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <Card elevation={2} sx={{ "&:hover": { boxShadow: "0 4px 8px rgba(0,0,0,0.12)" } }}>
                <ImageLoader imagePath={event.image} altText={event.title} />
                <CardContent sx={{ padding: '16px 24px' }}>
                    <Typography variant="h5" fontWeight="bold" style={{ marginBottom: '8px' }}>{event.title}</Typography>
                    <IconText icon={DateRangeIcon}>Date: {event.date}</IconText>
                    <IconText icon={AccessTimeIcon}>Time: {event.time.start || "N/A"} {event.time.end && "-"} {event.time.end}</IconText>
                    <IconText icon={LocationOnIcon}>Location: {event.location}</IconText>
                    {event.danceTypes && 
                        <IconText icon={DanceIcon}>Dance Types: {event.danceTypes.join(', ') || 'N/A'}</IconText>}
                    {event.musicBy && 
                        <IconText icon={MusicNoteIcon}>Music By: {event.musicBy || 'N/A'}</IconText>}
                    {event.priceInfo && 
                        <IconText icon={AttachMoneyIcon}>Price: {event.priceInfo}</IconText>}
                </CardContent>
                <Divider inset="none" />
                <CardActions>
                    <PriceBar event={event} />
                    <Button>
                        <Typography fontWeight="bold">
                            Learn More
                        </Typography>
                    </Button>
                </CardActions>
            </Card>
        </Link>
    );
  };

export default EventCard;