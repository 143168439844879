import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const FacebookAuthCallback = () => {
    const [searchParams] = useSearchParams();
    const [authCode, setAuthCode] = React.useState('');

    useEffect(() => {
        const code = searchParams.get('code');

        if (code) {
            console.log('Authorization Code:', code);
            setAuthCode(code);  // Set the authorization code to state
        } else {
            console.error('Authorization code not found');
        }
    }, [searchParams]);

    return (
        <div>
            <h1>OAuth Callback</h1>
            {authCode && <p>Authorization Code: {authCode}</p>}
        </div>
    );
};

export default FacebookAuthCallback;
