import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyClVPsdCl1Agfl2iMJc2DibacOX7AE6IGo",
  authDomain: "salsa-hub.firebaseapp.com",
  projectId: "salsa-hub",
  storageBucket: "salsa-hub.appspot.com",
  messagingSenderId: "439193984268",
  appId: "1:439193984268:web:7f74ef5dc89c1dfdaffd29",
  measurementId: "G-806XMY732W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { analytics, storage, firestore };