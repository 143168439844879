import React from 'react';
import { Link } from "react-router-dom";
import { AppBar, BottomNavigation, BottomNavigationAction } from '@mui/material';

const Footer = () => {
  return (
    <AppBar position="fixed" color="primary" style={{top: "auto", bottom: 0}}>
      <BottomNavigation showLabels={true} >
        <BottomNavigationAction component={Link} to="/" color="inherit" label="Home" />
        <BottomNavigationAction component={Link} to="/disclaimer" color="inherit" label="Disclaimer" />
      </BottomNavigation>
    </ AppBar>
  );
};

export default Footer;