import Typography from '@mui/material/Typography';

const PriceBar = ({ event }) => {

  return (
    <Typography sx={{ mr: 'auto' }}>
        {event.price === "0" ? <span style={{color: 'green'}}>Free</span> : (event.price && "Ab " + event.price)}
    </Typography>
  );
}

export default PriceBar;