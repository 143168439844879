import React, { useEffect } from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';

const Disclaimer = () => {

    logEvent(analytics, 'screen_view', {
        screen_name: 'Disclaimer',
        screen_class: 'Disclaimer.js'
    });

    useEffect(() => {
        // set the page title
        document.title = 'Disclaimer | Salsa Hub Bonn';
    }, []);

  return (
    <>
        <Helmet>
            <title>Disclaimer | Salsa Hub Bonn</title>
            <meta name="description" content="Read the disclaimer" />
            <link rel="canonical" href={"https://bonnsalsahub.com/disclaimer"} />
        </Helmet>
        <Container style={{ paddingBottom: '64px' }}>
        <Paper elevation={3} sx={{ padding: { xs: 2, md: 4 }, overflowX: 'auto' }}>
            <Typography variant="h1" style={{ wordWrap: 'break-word', fontSize: '2.5rem' }}>Disclaimer</Typography>
            <Typography variant="body1" paragraph>
            <strong>No Liability</strong>
            </Typography>
            <Typography variant="body2" paragraph>
            The information, software, and service provided by Salsa Hub are provided "as is" without any warranties or representations, either express or implied. Salsa Hub and its affiliates, agents, and licensors cannot and do not warrant the accuracy, completeness, timeliness, non-infringement, merchantability, or fitness for a particular purpose of the information, software, and service available through the website.
            </Typography>
            <Typography variant="body1" paragraph>
            <strong>No Responsibility for Errors or Other Inaccuracies</strong>
            </Typography>
            <Typography variant="body2" paragraph>
            Salsa Hub is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this site is provided "as is," with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied.
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>No Responsibility for External Links</strong>
            </Typography>
            <Typography variant="body2" paragraph>
                This website may contain links to other websites operated by third parties. Such links are provided for your convenience only. Salsa Hub does not control such websites and is not responsible for their content.
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>No Legal Responsibility</strong>
            </Typography>
            <Typography variant="body2" paragraph>
                In no event will Salsa Hub, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability, or other legal or equitable theory for any special, incidental, or consequential damages.
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>User's Acknowledgment of Risk</strong>
            </Typography>
            <Typography variant="body2" paragraph>
            By using this website, you acknowledge that you are using the information, software, and services at your own risk.
            </Typography>
        </Paper>
        </Container>
    </>
  );
};

export default Disclaimer;
