import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, Button, CardActions, Divider, Box } from '@mui/material';
import ImageLoader from './common/ImageLoader';
import { createEvent } from 'ics';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DanceIcon from '@mui/icons-material/EmojiPeople';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { logEvent } from 'firebase/analytics';
import { analytics, firestore } from './firebase';
import PriceBar from './ui/event_card/PriceBar';
import {doc, getDoc} from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';

const EventDetail = () => {

    const { id } = useParams();
    const [event, setEvent] = useState({
        id: id,
        time: { start: "00:00" },
        geo: {},
        danceTypes: [],
    });

    useEffect(() => {
        const fetchEvents = async () => {
            const eventRef = doc(firestore, "events", id);
            const snapshot = await getDoc(eventRef);
            if (snapshot.exists()) {
                const eventData = { id: snapshot.id, ...snapshot.data() };
                setEvent(eventData);
                // set the page title
                document.title = eventData.title + ' | Salsa Hub Bonn';
            } else {
                return <div>Event not found</div>;
            }
        };

        // Log the view to Firebase Analytics
        logEvent(analytics, 'screen_view', {
            screen_name: id,
            event_category: 'view_detail',
        });

        fetchEvents();
    }, [id]);

    const startDate = new Date(event.date + 'T' + event.time.start + ':00');
    const endDate = new Date(event.date + 'T' + event.time.end + ':00');
    // if endDateTime is not defined, set it to 2 hours after startDateTime
    if (startDate && isNaN(endDate.getTime())) {
        endDate.setTime(startDate.getTime() + 2 * 60 * 60 * 1000);
    }
    const duration = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60;
    const durationHours = Math.floor(duration);
    const durationMinutes = Math.floor((duration - durationHours) * 60);

    var calendar_event = {
        start: [
            startDate.getFullYear(), startDate.getMonth()+1, startDate.getDate(), 
            startDate.getHours(), startDate.getMinutes()],
        duration: { hours: durationHours, minutes: durationMinutes },
        title: event.title,
        description: event.description + '\n\n' + event.extraInfo + '\n\n' + event.priceInfo,
        location: event.location,
        ...(event.geo.lat && event.geo.long) && { geo: { lat: event.geo.lat, lon: event.geo.long } },
        url: 'https://salsa-hub.web.app/event/' + event.id,
        categories: event.danceTypes,
        busyStatus: 'BUSY',
    }

    async function addToCalendar () {

        // Log the event to Firebase Analytics
        logEvent(analytics, 'click_add_to_calendar', {
            event_name: 'add_to_calendar',
            event_category: 'button_click',
            event_label: event.id,
        });
        
        const filename = event.id + '.ics'
        const file = await new Promise((resolve, reject) => {
            createEvent(calendar_event, (error, value) => {
            if (error) {
                reject(error)
            }

            resolve(new File([value], filename, { type: 'text/calendar' }))
            })
        })
        const url = URL.createObjectURL(file);

        // trying to assign the file URL to a window could cause cross-site
        // issues so this is a workaround using HTML5
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(url);
    };

    const getGoogleMapsUrl = () => {
        if (event.geo && event.geo.lat && event.geo.long) {
            return `https://www.google.com/maps/?q=${event.geo.lat},${event.geo.long}`;
        } else {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`;
        }
    };

    function onClickShowOnMap() {

        // Log the event to Firebase Analytics
        logEvent(analytics, 'click_show_on_map', {
            event_name: 'show_on_map',
            event_category: 'button_click',
            event_label: event.id,
        });

        window.open(getGoogleMapsUrl(), '_blank');
    }

    return (
        <>
        <Helmet>
            <title>Event Details | Salsa Hub Bonn</title>
            <meta name="description" content="View details of this salsa event in Bonn" />
            <link rel="canonical" href={`https://bonnsalsahub.com/event/${id}`} />
        </Helmet>
        <Container style={{ paddingBottom: '64px' }}>
            <Card style={{ marginTop: '20px' }}>
                <ImageLoader imagePath={event.image} altText={event.title} />
                <CardContent>
                <Typography variant="h1" style={{ wordWrap: 'break-word', fontSize: '2.5rem' }} gutterBottom>
                    {event.title}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                            <DateRangeIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Date:
                        </Typography>
                        <Typography variant="body1">{event.date}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                            <AccessTimeIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Time:
                        </Typography>
                        <Typography variant="body1">{event.time.start || "N/A"} {event.time.end && "-"} {event.time.end}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <LocationOnIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Location:
                        </Typography>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="start">
                            <Typography variant="body1" mr={2}>
                                {event.location}
                            </Typography>
                            <Button 
                                size="small" 
                                onClick={onClickShowOnMap}
                                startIcon={<OpenInNew />}
                                >
                                <Typography fontWeight="bold">Show on Map</Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <DanceIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Dance Types:
                        </Typography>
                        <Typography variant="body1">{event.danceTypes.join(', ')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <MusicNoteIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Music By:
                        </Typography>
                        <Typography variant="body1">{event.musicBy || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <DescriptionIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Description:
                        </Typography>
                        <Typography variant="body1">{event.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <InfoIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Extra Info:</Typography>
                        <Typography variant="body1">{event.extraInfo}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <LinkIcon fontSize="small" style={{ marginRight: '8px' }} />
                            More Information:
                        </Typography>
                        <a href={event.url} target="_blank" rel="noopener noreferrer">
                            <Typography variant="body1">{event.url}</Typography>
                        </a>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <PersonIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Organizer:</Typography>
                        <Typography variant="body1">{event.organizer || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <AttachMoneyIcon fontSize="small" style={{ marginRight: '8px' }} />
                            Price:</Typography>
                        <Typography variant="body1">{event.priceInfo || "N/A"}</Typography>
                    </Grid>
                </Grid>
                </CardContent>
                <Divider inset="none" />
                <CardActions>
                    <PriceBar event={event} />
                    <Button onClick={addToCalendar}>
                        <Typography fontWeight="bold">
                            Add to Calendar
                        </Typography>
                    </Button>
                </CardActions>
            </Card>
        </Container>
        </>
    );
};

export default EventDetail;