import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import Disclaimer from './Disclaimer';
import Footer from './Footer';
import EventDetail from './EventDetail';
import FacebookAuth from './ui/auth/FacebookAuth';
import FacebookAuthCallback from './ui/auth/FacebookAuthCallback';

const App = () => {

    // add redirect from firebase domain to main domain
    if (window.location.hostname ===  "https://salsa-hub.web.app" ||
        window.location.hostname ===  "https://salsa-hub.firebaseapp.app" ||
        window.location.hostname ===  "salsa-hub.web.app" ||
        window.location.hostname === "salsa-hub.firebaseapp.com") {
            window.location.href = 'https://bonnsalsahub.com';
    }
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/event/:id" element={<EventDetail />} />
                <Route path="/auth/facebook" element={<FacebookAuth />} />
                <Route path="/auth/facebook/callback" element={<FacebookAuthCallback />} />
                
            </Routes>
            <Footer />
        </Router>
    );
    };

export default App;