import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import EventCard from './EventCard';
import { analytics, firestore } from './firebase';
import { logEvent } from 'firebase/analytics';
import {collection, getDocs, orderBy, query } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';

const Home = () => {

    const [events, setEvents] = useState([]);

    logEvent(analytics, 'screen_view', {
        screen_name: 'Home',
        screen_class: 'Home.js'
    });

    useEffect(() => {
        // set the page title
        document.title = 'Salsa Hub Bonn | Upcoming Salsa Events';
        const fetchEvents = async () => {
            const eventsRef = collection(firestore, "events");
            const snapshot = await getDocs(query(eventsRef, orderBy('id', `desc`)));
            const eventsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEvents(eventsData);
        };
        fetchEvents();
    }, []);

    return (
        <>
            <Helmet>
                <title>Salsa Hub Bonn | Upcoming Salsa Events</title>
                <meta name="description" content="Upcoming Salsa workshops, Salsa parties, and Salsa social dance events in Bonn Germany" />
                <link rel="canonical" href={"https://bonnsalsahub.com"} />
            </Helmet>
            <Container style={{ paddingBottom: '64px' }}>
                <div style={{ height: '24px' }} />
                <Typography variant="h1" style={{ wordWrap: 'break-word', fontSize: '2.5rem' }}>Salsa Hub Bonn - Dance Events & Workshops</Typography>
                <Typography variant="h2" style={{ fontSize: '2rem' }} >Upcoming Salsa Events:</Typography>
                <div style={{ height: '16px' }} />
                <Grid container spacing={3}>
                    {events.map((event) => (
                        <Grid item xs={12} sm={6} md={4} key={event.id}>
                            <EventCard event={event} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
  );
}

export default Home;